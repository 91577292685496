const ReviewTable = ({
  items
}: {
  items: {
    title?: string;
    children: {
      label: string;
      value: string | undefined;
      valueClassName?: string;
    }[];
  }[];
}) => (
  <div className="grid grid-cols-1 text-sm">
    {items.map((item) => (
      <div key={item.title} className="mb-4">
        {item?.title && <div className="text-xl mb-2">{item.title}</div>}
        {item.children
          .filter((child) => !!child.value)
          .map((child) => (
            <div key={child.label} className="grid mb-1">
              <span
                title={child.value}
                className="break-all py-1 px-3 border-l-2 border-gray-700 bg-gray-50"
              >
                <div className="italic">{child.label}</div>
                <div className="font-semibold text-md">{child.value}</div>
              </span>
            </div>
          ))}
      </div>
    ))}
  </div>
);

export default ReviewTable;
