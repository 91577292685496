import {
  addYears,
  endOfDay,
  isBefore,
  isToday,
  isTomorrow,
  roundToNearestMinutes,
  startOfDay
} from 'date-fns';
import DatePicker from 'react-datepicker';
import { UseFormReturn } from 'react-hook-form';
import { CALENDAR_EVENT_DURATION_MINUTES } from 'lib/constants';
import { toDateTimeZoned } from 'lib/utils';

const roundingOptions = { nearestTo: 15 };

const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);

interface Props {
  name: string;
  form: UseFormReturn<any>;
  title?: string;
  subTitle?: string;
  placeholder?: string;
  disabled?: boolean;
  startMinTime?: Date;
  startMinDate?: Date;
}

const DateInput = ({
  name,
  title,
  placeholder = 'Select date...',
  subTitle,
  form,
  disabled,
  startMinTime: startMinTimeExternal,
  startMinDate: startMinDateExternal
}: Props) => {
  const { register, watch, setValue } = form;
  // const [excludedTimes, setExcludedTimes] = useState<string[]>([]);
  const date = watch(name);
  const nowTZ = toDateTimeZoned(new Date());

  // useEffect(() => {
  //   (async () => {
  //     const response = await fetch('/api/calendar/blocked-event-datetime').then(
  //       (r) => r.json()
  //     );

  //     setExcludedTimes(response);
  //   })();
  // }, []);

  const getNearestTimeAvailable = (date: Date): Date => {
    const roundedDate = roundToNearestMinutes(date, roundingOptions);
    // const isNotAvailable = excludedTimes.find((excludedTime) => {
    //   return (
    //     toISOStringTimeZoned(new Date(excludedTime)) ===
    //     roundedDate.toISOString()
    //   );
    // });

    // if (isNotAvailable) {
    //   return getNearestTimeAvailable(
    //     addMinutes(date, CALENDAR_EVENT_DURATION_MINUTES)
    //   );
    // }

    return roundedDate;
  };

  const startMinTime = () => {
    const startDateOrNow = date || nowTZ;
    if (isToday(startDateOrNow)) {
      return nowTZ;
    }

    if (isTomorrow(startDateOrNow)) {
      return startOfDay(startDateOrNow);
    }

    return startOfDay(nowTZ);
  };

  const startMaxTime = () => {
    return endOfDay(nowTZ);
  };

  return (
    <div className="grid gap-4">
      <div className="flex-1 gap-1">
        {title && <div className="mb-2 font-semibold">{title}</div>}
        {subTitle && <small className="mb-2 block">{subTitle}</small>}
        <DatePicker
          autoComplete="off"
          className="w-full rounded-lg border border-gray-400 p-4 text-center"
          selected={date}
          showTimeSelect
          disabled={disabled}
          customInput={<DatepickerInput />}
          withPortal
          calendarStartDay={1}
          placeholderText={placeholder}
          minTime={startMinTimeExternal || startMinTime()}
          maxTime={startMaxTime()}
          minDate={startMinDateExternal || nowTZ}
          maxDate={addYears(nowTZ, 5)}
          timeFormat="HH:mm"
          timeIntervals={CALENDAR_EVENT_DURATION_MINUTES}
          timeCaption="Time"
          // excludeTimes={excludedTimes
          //   .map((time) => new Date(time))
          //   .filter((time) => date && isSameDay(date, time))}
          dateFormat="yyyy-MM-dd, HH:mm"
          {...register(name)}
          onChange={(date: Date) => {
            const newDate = roundToNearestMinutes(date, roundingOptions);

            setValue(
              name,
              getNearestTimeAvailable(
                isBefore(newDate, nowTZ) ? nowTZ : newDate
              )
            );
          }}
        />
      </div>
    </div>
  );
};

export default DateInput;
