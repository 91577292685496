import { UseFormReturn } from 'react-hook-form';
import { Radio } from '@material-tailwind/react';
import { EventType, ShortTermBookingFormValues } from 'lib/types';

interface Props {
  form: UseFormReturn<ShortTermBookingFormValues>;
  onChange?: (eventType: EventType) => void;
  disabledEvents?: ('pick-up' | 'drop-off' | 'both')[];
}

const EventTypeInput = ({ form, onChange, disabledEvents = [] }: Props) => {
  const { register } = form;

  return (
    <div className="animate-fade-in">
      {!disabledEvents.includes('pick-up') && (
        <div>
          <Radio
            id="pick-up"
            value="pick-up"
            label="Pick up"
            onClick={(e) => onChange?.(e.currentTarget.value as EventType)}
            {...register('eventType')}
          />
          <p className="ml-8 text-gray-600">
            ... for when you want to collect your car.
          </p>
        </div>
      )}
      {!disabledEvents.includes('drop-off') && (
        <div>
          <Radio
            id="drop-off"
            value="drop-off"
            defaultChecked
            label="Drop off"
            onClick={(e) => onChange?.(e.currentTarget.value as EventType)}
            {...register('eventType')}
          />
          <p className="ml-8 text-gray-600">
            ... for when you want to park your car
          </p>
        </div>
      )}
      {!disabledEvents.includes('both') && (
        <div>
          <Radio
            id="both"
            value="both"
            label="Round trip"
            onClick={(e) => onChange?.(e.currentTarget.value as EventType)}
            {...register('eventType')}
          />
          <p className="ml-8 text-gray-600">
            ... for when you know the duration of your stay
          </p>
        </div>
      )}
    </div>
  );
};

export default EventTypeInput;
