import { getBrandConfig } from 'config/brands';
import React from 'react';

const MinimumTimeWarning = ({ hours }: { hours: string }) => {
  return (
    <div className="mt-4 grid gap-2 p-4 bg-red-500 text-white mb-4 rounded-lg">
      <p>
        <b>
          Your reservation seems to be required within the next {hours} hours
        </b>
        , please contact us via mail at {getBrandConfig().supportEmail} or send
        a WhatsApp message to{' '}
        <a
          className="underline"
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/34693751684"
        >
          {getBrandConfig().supportNumber} (opens WhatsApp)
        </a>{' '}
        to check last-minute availability!
      </p>
    </div>
  );
};

export default MinimumTimeWarning;
