import {
  addHours,
  addMonths,
  addYears,
  differenceInHours,
  endOfDay,
  endOfMonth,
  isBefore,
  isToday,
  isTomorrow,
  roundToNearestMinutes,
  startOfDay
} from 'date-fns';
import DatePicker from 'react-datepicker';
import { UseFormReturn } from 'react-hook-form';
import { CALENDAR_EVENT_DURATION_MINUTES } from 'lib/constants';
import { toDateTimeZoned } from 'lib/utils';

const roundingOptions = { nearestTo: 15 };
const MINIMUM_HOUR_DIFFERENCE = 12;

interface Props<T extends UseFormReturn<any>> {
  form: T;
  disabled?: boolean;
  swap?: boolean;
}

const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);

const DateInputs = <T extends UseFormReturn<any>>({
  form,
  disabled,
  swap
}: Props<T>) => {
  const { register, watch, setValue } = form;
  // const [excludedTimes, setExcludedTimes] = useState<string[]>([]);
  const [startDate, endDate]: [Date, Date] = watch(['startDate', 'endDate']);
  const nowTZ = toDateTimeZoned(new Date());

  // useEffect(() => {
  //   (async () => {
  //     const response = await fetch('/api/calendar/blocked-event-datetime').then(
  //       (r) => r.json()
  //     );

  //     setExcludedTimes(response);
  //   })();
  // }, []);

  const getNearestTimeAvailable = (date: Date): Date => {
    const roundedDate = roundToNearestMinutes(date, roundingOptions);
    // const isNotAvailable = excludedTimes.find((excludedTime) => {
    //   return (
    //     toISOStringTimeZoned(new Date(excludedTime)) ===
    //     roundedDate.toISOString()
    //   );
    // });

    // if (isNotAvailable) {
    //   return getNearestTimeAvailable(
    //     addMinutes(date, CALENDAR_EVENT_DURATION_MINUTES)
    //   );
    // }

    return roundedDate;
  };

  const startMinTime = () => {
    const startDateOrNow = startDate || nowTZ;
    if (isToday(startDateOrNow)) {
      return nowTZ;
    }

    if (isTomorrow(startDateOrNow)) {
      return startOfDay(startDateOrNow);
    }

    return startOfDay(nowTZ);
  };

  const startMaxTime = () => {
    return endOfDay(nowTZ);
  };

  const endMinTime = () => {
    if (
      startDate &&
      differenceInHours(
        endDate || addHours(nowTZ, MINIMUM_HOUR_DIFFERENCE),
        startDate
      ) <= MINIMUM_HOUR_DIFFERENCE
    ) {
      return addHours(startDate, MINIMUM_HOUR_DIFFERENCE);
    }

    return startOfDay(nowTZ);
  };

  const DropOffHeader = (
    <>
      <div className="font-semibold">Drop off</div>
      <small className="mb-2 block">Park your vehicle</small>
    </>
  );
  const PickUpHeader = (
    <>
      <div className="font-semibold">Pick up</div>
      <small className="mb-2 block">Collect your vehicle</small>
    </>
  );

  return (
    <div className="flex gap-4 items-center">
      <div className="flex-1 gap-1">
        {!swap ? DropOffHeader : PickUpHeader}
        <DatePicker
          autoComplete="off"
          calendarStartDay={1}
          className="w-full rounded-lg border border-gray-400 p-4 text-center"
          selected={startDate}
          showTimeSelect
          customInput={<DatepickerInput />}
          withPortal
          placeholderText="Select date..."
          disabled={disabled}
          minTime={startMinTime()}
          maxTime={startMaxTime()}
          minDate={nowTZ}
          maxDate={addYears(nowTZ, 5)}
          timeFormat="HH:mm"
          timeIntervals={CALENDAR_EVENT_DURATION_MINUTES}
          timeCaption="Time"
          // excludeTimes={excludedTimes
          //   .map((time) => new Date(time))
          //   .filter((time) => startDate && isSameDay(startDate, time))}
          dateFormat="yyyy-MM-dd, HH:mm"
          {...register('startDate')}
          onChange={(date: Date) => {
            const newDate = roundToNearestMinutes(date, roundingOptions);
            setValue(
              'startDate',
              getNearestTimeAvailable(
                isBefore(newDate, nowTZ) ? nowTZ : newDate
              )
            );

            if (!endDate) {
              setValue('endDate', addHours(date, MINIMUM_HOUR_DIFFERENCE));
            }

            if (
              endDate &&
              differenceInHours(endDate, date) < MINIMUM_HOUR_DIFFERENCE
            ) {
              setValue('endDate', addHours(date, MINIMUM_HOUR_DIFFERENCE));
            }
          }}
        />
      </div>
      <div className="flex-1 gap-1">
        {swap ? DropOffHeader : PickUpHeader}
        <DatePicker
          autoComplete="off"
          calendarStartDay={1}
          className="w-full rounded-lg border border-gray-400 p-4 text-center"
          selected={endDate}
          showTimeSelect
          customInput={<DatepickerInput />}
          withPortal
          placeholderText="Select date..."
          disabled={disabled || !startDate}
          minTime={endMinTime()}
          maxTime={endOfDay(nowTZ)}
          minDate={addHours(startDate!, MINIMUM_HOUR_DIFFERENCE)}
          maxDate={endOfMonth(addMonths(startDate!, 6))}
          timeFormat="HH:mm"
          timeIntervals={CALENDAR_EVENT_DURATION_MINUTES}
          timeCaption="Time"
          dateFormat="yyyy-MM-dd, HH:mm"
          {...register('endDate')}
          onChange={(date) => {
            if (date) {
              let newDate = date;

              if (
                differenceInHours(date, startDate!) < MINIMUM_HOUR_DIFFERENCE
              ) {
                newDate = addHours(startDate!, MINIMUM_HOUR_DIFFERENCE);
              }

              setValue(
                'endDate',
                roundToNearestMinutes(newDate, roundingOptions)
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default DateInputs;
