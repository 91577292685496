import { UseFormReturn } from 'react-hook-form';
import { Radio } from '@material-tailwind/react';

const ParkingTypeInput = (form: UseFormReturn<any, any>) => {
  const { register, watch } = form;
  const { parkingType } = watch();

  return (
    <div className="animate-fade-in">
      <div>
        <Radio
          id="outdoor"
          value="outdoor"
          defaultChecked={parkingType === 'outdoor'}
          label="Outdoors"
          {...register('parkingType')}
        />
      </div>
      <div>
        <Radio
          id="covered"
          value="covered"
          defaultChecked={parkingType === 'covered'}
          label="Indoors"
          {...register('parkingType')}
        />
      </div>
      <div>
        <Radio
          id="motorhome"
          value="motorhome"
          defaultChecked={parkingType === 'motorhome'}
          label="Motorhome"
          {...register('parkingType')}
        />
      </div>
    </div>
  );
};

export default ParkingTypeInput;
