import React, { useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { Button } from '@material-tailwind/react';
import ActiveParking from 'components/ActiveParking';
import ContractParkingForm from 'components/ContractParkingForm';
import ShortTermForm from 'components/ShortTermParkingForm';
import { useAppContext } from 'context/AppContext';
import { useAuthContext } from 'context/AuthContextProvider';
import Layout from 'layouts';
import { db } from 'lib/firebase';

const types = [
  {
    label: 'Short term',
    subLabel: '0-6 months',
    id: 'short'
  },
  {
    label: 'Contract',
    subLabel: 'Pick up / Drop off',
    id: 'contract'
  }
] as const;

const Booking = () => {
  const user = useAuthContext();
  const ref = doc(db, 'users', user!.uid);
  const [userData, loading] = useDocumentData(ref);
  const { setCurrentEventType, currentEventType } = useAppContext();

  useEffect(() => {
    setCurrentEventType(undefined);
  }, [setCurrentEventType]);

  const reset = () => setCurrentEventType(undefined);

  if (loading) return null;

  return (
    <div className="flex w-full animate-fade-in flex-col items-center">
      {!currentEventType && (
        <>
          <ActiveParking />
          <h1 className="p-4">BOOK PARKING</h1>
          <div className="grid h-[65vh] w-full gap-2 rounded">
            {types.map((item) => (
              <Button
                key={item.id}
                color="red"
                className="grid w-full flex-1 place-items-center"
                type="button"
                onClick={() => setCurrentEventType(item.id)}
              >
                <div>
                  <div className="text-xl">{item.label}</div>
                  <div className="text-lg font-normal">{item.subLabel}</div>
                </div>
              </Button>
            ))}
          </div>
        </>
      )}
      {currentEventType === 'short' && (
        <ShortTermForm
          defaultValues={userData?.recentBookingDetails || {}}
          {...{ reset }}
        />
      )}
      {currentEventType === 'contract' && (
        <ContractParkingForm
          defaultValues={userData?.recentBookingDetails || {}}
          {...{ reset }}
        />
      )}
    </div>
  );
};

Booking.getLayout = (page: React.ReactElement) => {
  return <Layout>{page}</Layout>;
};

export default Booking;
