import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, query, where } from 'firebase/firestore';
import BookingItemActive from 'components/BookingItemActive';
import { useAuthContext } from 'context/AuthContextProvider';
import { db } from 'lib/firebase';
import { AllBookingTypes } from 'lib/types';

const ActiveParking = () => {
  const user = useAuthContext();
  const ref = collection(db, 'bookings');

  const [data] = useCollection(
    query(
      ref,
      where('userId', '==', user!.uid),
      where('bookingType', '==', 'short'),
      where('eventType', '==', 'drop-off')
    )
  );

  const activeParking = (data?.docs || []).filter(
    (item) => !item.data()?.isCancelled
  );

  if (activeParking?.length === 0) return null;

  return (
    <div className="grid w-full animate-fade-in gap-4 mb-8">
      <h1 className="text-center">ACTIVE PARKING</h1>
      <div className="grid w-full gap-4">
        {activeParking?.map((item) => {
          const booking = item.data() as Omit<
            AllBookingTypes,
            'startDate' | 'endDate'
          > & {
            startDate: string;
            endDate: string;
          };

          return <BookingItemActive key={item.id} id={item.id} {...booking} />;
        })}
      </div>
    </div>
  );
};

export default ActiveParking;
