import { differenceInHours } from 'date-fns';
import { useRouter } from 'next/router';
import { EventInput, EventInputContract } from 'pages/api/calendar/utils';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Textarea } from '@material-tailwind/react';
import DateInput from 'components/DateInput';
import DateInputs from 'components/DateInputs';
import EventTypeInput from 'components/EventTypeInput';
import MinimumTimeWarning from 'components/MinimumTimeWarning';
import ParkingFormRenderer from 'components/ParkingFormRenderer/ParkingFormRenderer';
import ReviewTable from 'components/ReviewTable';
import ServiceItemsTotal from 'components/Service/ServiceItemsTotal/ServiceItemsTotal';
import Services from 'components/Service/Services';
import Stripe from 'components/Stripe';
import UserDetailsInput from 'components/UserDetailsInput';
import { useAuthContext } from 'context/AuthContextProvider';
import { useServiceContext } from 'context/ServicesCartContextProvider';
import { FILTERS, LOCALE, dateOptions } from 'lib/constants';
import { db } from 'lib/firebase';
import { ContractParkingBookingFormValues } from 'lib/types';
import {
  capitalizeFirstLetter,
  toDateTimeZoned,
  toISOStringTimeZoned
} from 'lib/utils';

interface Props {
  defaultValues: Record<string, string>;
  reset: () => void;
}

const MIN_TIME_ALLOWED_BEFORE_BOOKING = 24;

const ContractParkingForm = ({ defaultValues, reset }: Props) => {
  const user = useAuthContext();
  const { cart, setCart } = useServiceContext();
  const router = useRouter();

  useEffect(() => {
    setCart([]);
  }, [setCart]);

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(0);
  const [isReversed, setIsReversed] = useState(false);
  const form = useForm<ContractParkingBookingFormValues>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: user?.email ?? '',
      registrationNumber: '',
      flightNumber: '',
      startDate: null,
      endDate: null,
      parkingType: null,
      specialRequests: '',
      eventType: 'pick-up',
      ...defaultValues
    }
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid }
  } = form;

  const {
    startDate,
    endDate,
    firstName,
    lastName,
    email,
    registrationNumber,
    flightNumber,
    eventType
  } = watch();

  const isBeforeMinBookingTime = !!(
    startDate &&
    differenceInHours(startDate, toDateTimeZoned(new Date())) <
      MIN_TIME_ALLOWED_BEFORE_BOOKING
  );

  const eventTitleMap = {
    'pick-up': 'Pick up',
    'drop-off': 'Drop off',
    both: 'Round trip'
  };

  const eventTitle = eventType && eventTitleMap[eventType];
  const isInvalidDate =
    eventType === 'both'
      ? startDate === null || endDate === null
      : startDate === null;

  const resolveDates = (startDate: Date, endDate: Date, reversed: boolean) => {
    if (reversed) {
      return { startDate: endDate, endDate: startDate };
    }

    return { startDate, endDate };
  };

  const onSubmit = async (formValues: ContractParkingBookingFormValues) => {
    try {
      setIsSubmitting(true);
      const userRef = doc(db, 'users', user!.uid);

      const {
        startDate,
        endDate,
        flightNumber,
        specialRequests,
        ...recentBookingDetails
      } = formValues;

      await updateDoc(userRef, {
        registrationNumbers: arrayUnion(formValues.registrationNumber),
        recentBookingDetails
      });

      const resolvedDates = resolveDates(startDate!, endDate!, isReversed);

      const response = await fetch(
        `/api/calendar/${
          eventType === 'both' ? 'add-event' : 'add-contract-event'
        }`,
        {
          method: 'POST',
          body: JSON.stringify({
            formValues: {
              ...formValues,
              bookingType: 'contract',
              phoneNumber: user?.phoneNumber || formValues.phoneNumber
            },
            userId: user?.uid ?? '',
            startDateISO: toISOStringTimeZoned(resolvedDates.startDate),
            ...(endDate && {
              endDateISO: toISOStringTimeZoned(resolvedDates.endDate)
            })
          } as EventInput)
        }
      ).then((r) => r.json());

      const { bookingId } = await response;

      if (!bookingId) {
        throw new Error('Failed');
      }

      if (typeof bookingId === 'string') {
        router.push(`/booking/contract/${bookingId}`);
      }
    } catch (err) {
      setErrorMessage(
        'Something went wrong while creating your booking, please try again.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitWithPayment = async (
    formValues: ContractParkingBookingFormValues
  ) => {
    const userRef = doc(db, 'users', user!.uid);

    const {
      startDate,
      endDate,
      flightNumber,
      specialRequests,
      ...recentBookingDetails
    } = formValues;

    await updateDoc(userRef, {
      registrationNumbers: arrayUnion(formValues.registrationNumber),
      recentBookingDetails
    });
    const resolvedDates = resolveDates(startDate!, endDate!, isReversed);

    const payload = {
      formValues: {
        ...formValues,
        bookingType: 'contract',
        phoneNumber: user?.phoneNumber || formValues.phoneNumber
      },
      userId: user?.uid,
      startDateISO: toISOStringTimeZoned(resolvedDates.startDate!),
      ...(endDate && {
        endDateISO: toISOStringTimeZoned(resolvedDates.endDate!)
      })
    } as EventInputContract;

    return payload;
  };

  const resolvedDates = resolveDates(startDate!, endDate!, isReversed);

  const steps = [
    {
      label: 'Make a reservation for',
      render: (
        <EventTypeInput
          form={form}
          onChange={(eventType) =>
            eventType === 'drop-off' && setValue('flightNumber', '')
          }
        />
      )
    },
    {
      label: `Schedule the date and time of desired parking times. We only accept bookings with a minimum of 24 hours ahead!`,
      disableNext: isInvalidDate,
      render: (
        <>
          <div>
            {eventType === 'both' ? (
              <div className="grid items-center">
                <DateInputs swap={!isReversed} form={form} />
                <div className="mt-4 grid gap-2 items-center justify-self-center text-sm">
                  <span className="text-center">
                    {!isReversed ? 'Arriving' : 'Departing'}
                  </span>
                  <Button
                    variant="text"
                    className="flex gap-2 items-center justify-self-center"
                    onClick={() => setIsReversed((s) => !s)}
                  >
                    <FontAwesomeIcon size="lg" icon={faArrowRightArrowLeft} />
                  </Button>
                </div>
              </div>
            ) : (
              <DateInput title="Start date" name="startDate" form={form} />
            )}
          </div>
          {isBeforeMinBookingTime && (
            <MinimumTimeWarning
              hours={String(MIN_TIME_ALLOWED_BEFORE_BOOKING)}
            />
          )}
        </>
      )
    },
    {
      label: 'Enter your details',
      disableNext: !isValid,
      render: (
        <UserDetailsInput
          form={form}
          disabledFields={eventType === 'drop-off' ? ['flightNumber'] : []}
        />
      )
    },
    {
      label: 'Select extra services',
      disableNext: !isValid,
      render: (
        <Services
          filters={
            watch('eventType') === 'drop-off' ? [FILTERS.DROP_OFF_ONLY] : []
          }
        />
      )
    },
    {
      label: 'Review',
      render: (
        <div>
          <ReviewTable
            items={[
              {
                title: 'Person',
                children: [
                  {
                    label: 'Name',
                    value: `${firstName} ${lastName}`
                  },
                  {
                    label: 'Email',
                    value: email
                  }
                ]
              },
              {
                title:
                  eventType === 'pick-up' ? 'Flight and vehicle' : 'Vehicle',
                children: [
                  {
                    label: 'Flight number',
                    value: flightNumber
                  },
                  {
                    label: 'Registration number',
                    value: registrationNumber
                  }
                ]
              },
              {
                title: 'Date and time',
                children:
                  eventType === 'both'
                    ? [
                        {
                          label: 'Pick up',
                          value: resolvedDates.startDate?.toLocaleTimeString(
                            LOCALE,
                            dateOptions
                          )
                        },
                        {
                          label: 'Drop off',
                          value: resolvedDates.endDate?.toLocaleTimeString(
                            LOCALE,
                            dateOptions
                          )
                        }
                      ].sort((a, b) => a?.value?.localeCompare(b?.value))
                    : [
                        {
                          label: capitalizeFirstLetter(eventTitle || ''),
                          value: resolvedDates.startDate?.toLocaleTimeString(
                            LOCALE,
                            dateOptions
                          )
                        }
                      ]
              }
            ]}
          />
          <div>
            <div className="text-xl mt-8">Services</div>
            <div className="grid">
              <ServiceItemsTotal />
            </div>
          </div>
          <div className="mt-4">
            <Textarea
              label="Special requests"
              {...register('specialRequests')}
            />
          </div>
        </div>
      )
    },
    cart.length > 0
      ? {
          label: 'Payment',
          render: (
            <Stripe
              onSubmit={() => onSubmitWithPayment(form.getValues())}
              onComplete={(bookingId) => {
                router.push(`/booking/contract/${bookingId}`);
              }}
            />
          )
        }
      : undefined
  ].filter(Boolean);

  const currentStep = steps[step];

  return (
    <ParkingFormRenderer
      {...{
        currentStep,
        stepIndex: step,
        steps,
        errorMessage,
        eventTitle,
        isSubmitting
      }}
      title={`Contract customer parking ${
        eventTitle && `- ${capitalizeFirstLetter(eventTitle)}`
      }`}
      onBackClick={() => {
        if (step === 0) {
          reset();
        } else {
          setStep((s) => s - 1);
        }
      }}
      onNextClick={() => {
        setStep((s) => s + 1);
      }}
      onSubmit={handleSubmit(onSubmit)}
      disableNext={step === 1 && isBeforeMinBookingTime}
    />
  );
};

export default ContractParkingForm;
