import React from 'react';
import { Button } from '@material-tailwind/react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useAppContext } from 'context/AppContext';
import { useServiceContext } from 'context/ServicesCartContextProvider';

type Step =
  | {
      label: string;
      render: JSX.Element;
      disableNext?: undefined;
    }
  | {
      label: string;
      disableNext: boolean;
      render: JSX.Element;
    }
  | undefined;

interface Props {
  currentStep: Step;
  steps: Step[];
  stepIndex: number;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  onBackClick: () => void;
  onNextClick: () => void;
  isSubmitting: boolean;
  errorMessage: string;
  title: string;
  disableNext?: boolean;
  hideBack?: boolean;
}

const ParkingFormRenderer = ({
  currentStep,
  steps,
  stepIndex,
  onSubmit,
  onBackClick,
  onNextClick,
  errorMessage,
  title,
  isSubmitting,
  disableNext = false,
  hideBack = false
}: Props) => {
  const { isPaymentProcessing } = useAppContext();
  const { cart } = useServiceContext();

  return (
    <>
      {(isSubmitting || isPaymentProcessing) && (
        <LoadingSpinner message="Creating your booking" />
      )}
      <form
        className="w-full max-w-4xl rounded-lg bg-white p-6 shadow-lg lg:p-10"
        onSubmit={onSubmit}
      >
        <h1 className="mb-4">{title}</h1>
        <div className="mb-8 grid" key={currentStep?.label}>
          <h3 className="mb-8">{currentStep?.label}</h3>

          <div>{currentStep?.render}</div>
        </div>
        {errorMessage && (
          <p className="bg-red-500 text-white p-4 mb-4">{errorMessage}</p>
        )}
        <div className="mb-4 flex w-full justify-center gap-2">
          {!hideBack && (
            <Button
              color="red"
              variant="outlined"
              disabled={isPaymentProcessing}
              onClick={() => onBackClick()}
            >
              Back
            </Button>
          )}
          {!disableNext && stepIndex < steps.length - 1 && (
            <Button
              disabled={currentStep?.disableNext}
              color="red"
              onClick={() => onNextClick()}
            >
              Next
            </Button>
          )}
          {stepIndex === steps.length - 1 && cart.length === 0 && (
            <Button disabled={isSubmitting} color="red" type="submit">
              Submit
            </Button>
          )}
        </div>
        <div className="flex justify-center gap-2">
          {stepIndex + 1} / {steps.length}
        </div>
      </form>
    </>
  );
};

export default ParkingFormRenderer;
