import { useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { UseFormReturn } from 'react-hook-form';
import { doc, setDoc } from 'firebase/firestore';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@material-tailwind/react';
import { useAppContext } from 'context/AppContext';
import { useAuthContext } from 'context/AuthContextProvider';
import { db } from 'lib/firebase';
import { UserDetails } from 'lib/types';

interface UserType {
  registrationNumbers: string[];
}

const Chip = ({
  label,
  onClick,
  onClose
}: {
  label: string;
  onClose: () => void;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  return (
    <div
      onClick={onClick}
      className="relative grid cursor-pointer grid-flow-col place-items-center rounded-full border border-blue-500 py-1 pl-4 pr-2.5 text-sm text-blue-500 hover:border-blue-700 hover:text-blue-700"
    >
      <span className="mr-2">{label}</span>
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
          onClose();
        }}
      >
        <FontAwesomeIcon size="sm" icon={faTimesCircle} />
      </button>
    </div>
  );
};

interface Props {
  form: UseFormReturn<any>;
  disabledFields?: (keyof UserDetails)[];
}

const UserDetailsInput = ({ disabledFields, form }: Props) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = form;

  const { setCurrentBookingDetails } = useAppContext();
  const user = useAuthContext();
  const ref = doc(db, 'users', user!.uid);
  const [value, loading] = useDocumentData(ref);
  const userRegistrationNumbers =
    (value as UserType)?.registrationNumbers || [];

  const removeChip = async (item: string) => {
    const docRef = doc(db, 'users', user!.uid);
    await setDoc(docRef, {
      registrationNumbers: userRegistrationNumbers.filter(
        (regNr) => regNr !== item
      )
    });
  };

  useEffect(() => {
    return () => {
      setCurrentBookingDetails(form.getValues());
    };
  }, [form, setCurrentBookingDetails]);

  return (
    <div className="grid gap-6">
      <div className="grid gap-3">
        <Input
          label="First name"
          error={!!errors?.firstName}
          type="text"
          {...register('firstName', { required: true })}
        />
        <Input
          label="Last name"
          error={!!errors?.lastName}
          {...register('lastName', { required: true })}
        />
      </div>
      <Input
        label="Email"
        type="email"
        error={!!errors?.email}
        {...register('email', {
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address'
          }
        })}
      />
      {!user?.phoneNumber && (
        <Input
          type="number"
          pattern="[0-9]*"
          label="Phone number"
          error={!!errors?.phoneNumber}
          {...register('phoneNumber', { required: true })}
        />
      )}
      <div className="grid gap-3">
        <Input
          label="Car registration number"
          error={!!errors?.registrationNumber}
          {...register('registrationNumber', { required: true })}
          onChange={(event) => {
            setValue(
              'registrationNumber',
              event.target.value.replace(/[^a-zA-Z0-9]/g, '')
            );
          }}
        />
        <div className="flex flex-wrap gap-2">
          {loading ? (
            <div>Loading your vehicles...</div>
          ) : (
            userRegistrationNumbers.map((item) => (
              <Chip
                key={item}
                onClose={() => removeChip(item)}
                label={item}
                onClick={() => {
                  setValue('registrationNumber', item);
                }}
              />
            ))
          )}
        </div>
      </div>
      {!disabledFields?.includes('flightNumber') && (
        <Input
          label="Flight number"
          error={!!errors?.flightNumber}
          {...register('flightNumber', { required: true })}
        />
      )}
    </div>
  );
};

export default UserDetailsInput;
