import router from 'next/router';
import { faSquareParking } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, Typography } from '@material-tailwind/react';
import { AllBookingTypes } from 'lib/types';
import { formatDate } from 'lib/utils';

const Row = ({ value = '' }: { value?: string | null }) => (
  <div className="mb-2">
    <span>{value}</span>
  </div>
);

type BookingItemActive = Omit<AllBookingTypes, 'startDate' | 'endDate'> & {
  startDate: string;
  endDate: string;
};

interface Props extends BookingItemActive {
  id: string;
}

const BookingItemActive = ({
  id,
  registrationNumber,
  isCancelled,
  startDate
}: Props) => {
  return (
    <Card className="rounded-md border-0 border-l-4 border-red-500 shadow-lg duration-100">
      <CardBody className="flex gap-4 items-center justify-between text-sm text-black">
        <div>
          {isCancelled && (
            <div className="px-4 py-2 rounded mb-4 bg-red-500 text-white font-bold">
              CANCELLED
            </div>
          )}
          <div className="mb-2 flex items-center">
            <FontAwesomeIcon
              className="mr-2 text-red-500"
              size="2x"
              icon={faSquareParking}
            />
            <Typography variant="h5" className=" uppercase">
              {registrationNumber}
            </Typography>
          </div>
          <Row value={`Drop off: ${startDate && formatDate(startDate)}`} />
        </div>
        <Button
          className="whitespace-nowrap"
          onClick={() => router.push(`/book-pick-up/${id}`)}
          color="red"
        >
          Book pick up
        </Button>
      </CardBody>
    </Card>
  );
};

export default BookingItemActive;
